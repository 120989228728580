import { useEffect, useState, useMemo } from "react";
import useFaqs from "@hooks/data/useFaqs";
import useFaqTags from "@hooks/data/useFaqTags";
import SimpleSelect from "../formElements/inputs/select/SimpleSelect";
import { useRouter } from "next/router";
import {
  containerVariants,
  itemVariants,
} from "@lib/motion/variants/defaultLoadin";
import { motion } from "framer-motion";
import { EmojiSadIcon } from "@heroicons/react/outline";

const translations = {
  nl: {
    title: "Veelgestelde vragen",
    category: "Categorie",
    noFaqsMessage: "Er zijn tijdelijk geen FAQ's beschikbaar.",
  },
  en: {
    title: "Frequently Asked Questions",
    category: "Category",
    noFaqsMessage: "There are no FAQ's available",
  },
  fr: {
    title: "Questions fréquemment posées",
    category: "Catégorie",
    noFaqsMessage: "Il n'y a pas de FAQ disponible.",
  },
};

export default function StandardFaqSection({ defaultTag, dark, className }) {
  const { locale } = useRouter();
  const { faqs, isFaqsLoading, isFaqsError } = useFaqs(locale);
  const { tags, isTagsLoading, isTagsError } = useFaqTags(locale);
  const content = translations[locale]
  const [tagOptions, setTagOptions] = useState([]);
  const [activeFaqs, setActiveFaqs] = useState([]);
  const [activeTag, setActiveTag] = useState(
    defaultTag
      ? {
        value: defaultTag.tag,
        label: defaultTag.tag,
      }
      : { value: "", label: "" }
  );

  useEffect(() => {

    setActiveTag({ value: defaultTag.tag, label: defaultTag.tag })
  }, [locale, setActiveTag, defaultTag.tag])

  useEffect(() => {
    if (!isTagsError && !isTagsLoading) {
      setTagOptions(tags.map((tag) => ({ value: tag.tag, label: tag.tag })));
    }
  }, [tags, isTagsError, isTagsLoading]);

  useEffect(() => {
    const filter = (faq, tag) => {
      let res = false;
      faq.tags.forEach((item) => {
        if (item.tag === tag.value) {
          res = true;
        }
      });
      return res;
    };

    if (faqs) {
      setActiveFaqs(
        faqs
          .filter((faq) => filter(faq, activeTag))
          .slice(0, 8)
          .sort((a, b) => b.answer.length - a.answer.length)
      );
    }
  }, [faqs, setActiveFaqs, activeTag]);

  return (
    <div className={`${className}`}>
      <div className="container mt-24">
        <h2
          className={`text-3xl font-bold  ${dark ? "text-slate-200" : "text-slate-700"
            }`}
        >
          {content.title}
        </h2>

        <div className="mt-6 grid grid-cols-2">
          <div className="col-span-2 md:col-span-1">
            <SimpleSelect
              label={content.category}
              name="activeTag"
              value={activeTag}
              setValue={setActiveTag}
              options={tagOptions}
              isSearchable={false}
              hasErrorMessage={false}
              dark={dark}
            />
          </div>
        </div>
        <motion.div layout className="pt-10">
          <motion.dl
            variants={containerVariants}
            initial="hidden"
            animate="visible"
            layout
            className="space-y-10 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-12 md:space-y-0"
          >
            {!isFaqsLoading && activeFaqs.length > 0 ?
              activeFaqs.map((faq) => (
                <Faq key={faq.id} faq={faq} dark={dark} />
              )) : <div className={`flex flex-row space-x-2 ${dark ? 'text-slate-200' : 'text-slate-700'}`}><EmojiSadIcon className="h-6 w-6" aria-hidden={true} /><span className='font-medium'>{content.noFaqsMessage}</span> </div>}
          </motion.dl>
        </motion.div>
      </div>
    </div>
  );
}

const Faq = ({ faq, dark }) => {
  const [open, setOpen] = useState(false);
  return (
    <motion.div layout key={faq.id} variants={itemVariants} className="max-w-full">
      <motion.dt
        layout
        className={`text-lg font-medium leading-6 ${dark ? "text-slate-300" : "text-slate-800"
          }`}
      >
        {faq.question}
      </motion.dt>
      <motion.dd layout className={`mt-2 text-base ${dark ? 'text-slate-400' : 'text-slate-500'}`}>
        {faq.answer.length <= 300 ? (
          faq.answer
        ) : open ? (
          faq.answer
        ) : (
          <motion.div layout>
            {faq.answer.slice(0, 300) + "..."}{" "}
            <button
              className={`font-medium  ${dark ? "text-slate-300" : "text-slate-800"
                }`}
              onClick={() => {
                setOpen(true);
              }}
            >
              Lees meer
            </button>
          </motion.div>
        )}
      </motion.dd>
    </motion.div>
  );
};
