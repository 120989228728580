import { isEmpty } from "lodash-es";
import Select from "react-select";
import CustomErrorMessage from "../shared/CustomErrorMessage";
// const styles = {
//   input: (provided) => ({
//     ...provided,
//   }),
//   control: (provided, state) => ({
//     ...provided,
//     borderColor: state.isFocused ? "#00AEEF" : "",
//   }),
//   container: (provided) => ({
//     ...provided,
//   }),
//   option: (provided, state) => ({
//     ...provided,
//     backgroundColor: state.isSelected
//       ? "#66cef5"
//       : state.isFocused
//       ? "#cceffc"
//       : "",
//   }),
// };
const SimpleSelect = ({
  label,
  name,
  value,
  setValue,
  options,
  isSearchable,
  isMulti,
  placeholder,
  autocomplete,
  hasErrorMessage = true,
  noOptionsMessage,
  dark
}) => {
  return (
    <>
      <label htmlFor={name} className={`text-base font-medium  sm:col-span-4 ${dark ? 'text-slate-200' : 'text-slate-700'}`}>
        {label}
      </label>
      <Select
        className="mt-1"
        options={options}
        defaultValue={isEmpty(value.value) ? null : value}
        name={name}
        id={name}
        isMulti={isMulti}
        onChange={setValue}
        isSearchable={isSearchable}
        placeholder={placeholder}
        inputProps={{ autoComplete: autocomplete ? autocomplete : "off" }}
        theme={(theme) => ({
          ...theme,

          colors: {
            ...theme.colors,
            primary: "#0AAEEF",
            primary25: "#e6f7fd",
            primary50: "#99dff9",
            primary75: "#33bef2",
          },
        })}
        aria-errormessage={name + "_select_error"}
        noOptionsMessage={() => noOptionsMessage}
      />
      {hasErrorMessage && (
        <CustomErrorMessage id={name + "_select_error"} name={name} />
      )}
    </>
  );
};

export default SimpleSelect;
