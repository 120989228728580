import SVG from "react-inlinesvg";

export default function MainFeatureShowcase({ data }) {
  return (
    <div className="mt-16 overflow-hidden lg:mt-24">
      <div className="container relative ">
        <svg
          className="absolute top-0 left-full -translate-x-1/2 -translate-y-3/4 transform lg:left-auto lg:right-full lg:translate-x-2/3 lg:translate-y-1/4"
          width={404}
          height={784}
          fill="none"
          viewBox="0 0 404 784"
          aria-hidden="true"
        >
          <defs>
            <pattern
              id="8b1b5f72-e944-4457-af67-0c6d15a99f38"
              x={0}
              y={0}
              width={20}
              height={20}
              patternUnits="userSpaceOnUse"
            >
              <rect
                x={0}
                y={0}
                width={4}
                height={4}
                className="text-gray-200"
                fill="currentColor"
              />
            </pattern>
          </defs>
          <rect
            width={404}
            height={784}
            fill="url(#8b1b5f72-e944-4457-af67-0c6d15a99f38)"
          />
        </svg>

        <div className="relative lg:grid lg:grid-cols-3 lg:gap-x-8">
          <div className="lg:col-span-1">
            <div className=" flex flex-col space-y-3">
              <h2 className="text-3xl leading-tight  text-gray-700 md:text-4xl ">
                {data.title1}{" "}
                <span className="text-3xl text-gray-500 md:text-4xl">
                  {data.title2}
                </span>
              </h2>
            </div>
          </div>
          <dl className=" mt-6 space-y-10 sm:grid sm:grid-cols-2 sm:gap-x-8 sm:gap-y-10 sm:space-y-0 lg:col-span-2 lg:mt-0">
            {data.features.map((feature) => (
              <div key={feature.id}>
                <div>
                  <div className="flex h-14 w-14 items-center justify-center rounded-md bg-ifixers text-white">
                    <SVG src={feature.icon} className="h-8 w-8" />
                  </div>
                  <p className="mt-5 text-lg font-medium leading-6 text-gray-900">
                    {feature.title}
                  </p>
                </div>
                <div className="mt-2 text-base text-gray-500">
                  {feature.text}
                </div>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
}
