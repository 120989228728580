import AffiliateShowcaseRow from "./AffiliateShowcaseRow";
const AffiliateShowcase = ({ data, items }) => {
  return (
    <section className="mt-16 bg-slate-200 lg:mt-24">
      <div className="mx-auto flex max-w-3xl flex-col space-y-2 px-4 pt-16 pb-8">
        <h2 className="text-4xl text-gray-700  lg:text-5xl">
          {data.title1}
          {/* <span

            className="cursor-pointer bg-gradient-to-br from-ifixers via-ifixers  to-green-400 bg-clip-text text-4xl font-bold text-transparent transition-all hover:from-darkifixers-100 hover:to-ifixers lg:text-5xl"
          >
            {data.title2}
          </span> */}
        </h2>
      </div>
      <div className="flex flex-col items-center justify-center space-y-8 overflow-hidden pb-16 pt-8">
        <AffiliateShowcaseRow
          items={items.slice(0, items.length / 2)}
          middle={true}
        />
        <AffiliateShowcaseRow items={items.slice(items.length / 2 + 1)} />
      </div>
    </section>
  );
};

export default AffiliateShowcase;
