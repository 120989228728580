import useSWRImmutable from "swr/immutable";

const fetcher = (url) => fetch(url).then((res) => res.json());

export default function useFaqs(locale) {
  const { data, error } = useSWRImmutable(
    `/api/cache/faqs?locale=${locale}`,
    fetcher
  );

  return {
    faqs: data,
    isFaqsLoading: !error && !data,
    isFaqsError: error,
  };
}
