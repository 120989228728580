import { motion } from "framer-motion";
import BrandBox from "./BrandBox";

const BrandShowCase = ({ data, items }) => {
  return (
    <section>
      <div className="container mt-16 lg:mt-24">
        <div className="flex flex-col  space-y-3">
          <h2 className="text-3xl text-gray-700  md:text-4xl  ">
            {data.title1}{" "}
            <span className="text-3xl text-gray-500 md:text-4xl">
              {data.title2}
            </span>
          </h2>
        </div>
        <div className="mt-6 overflow-hidden rounded-2xl bg-gradient-to-br from-darkifixers-300 to-darkifixers-700">
          <motion.div
            animate={{ translateX: 350 }}
            transition={{
              duration: 16,
              repeat: Infinity,
              repeatType: "reverse",
            }}
            className="flex -translate-x-28 transform flex-row items-center justify-center space-x-6 py-16 md:py-28"
          >
            {items?.map((item) => (
              <BrandBox key={item?.id} item={item} />
            ))}
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default BrandShowCase;
