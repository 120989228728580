import useSWRImmutable from "swr/immutable";

const fetcher = (url) => fetch(url).then((res) => res.json());

export default function useFaqs(locale) {
  const { data, error } = useSWRImmutable(
    `/api/cache/faqTags?locale=${locale}`,
    fetcher
  );

  return {
    tags: data,
    isTagsLoading: !error && !data,
    isTagsError: error,
  };
}
