import { ArrowRightIcon } from "@heroicons/react/solid";
import { motion } from "framer-motion";
import { PropTypes } from "prop-types";
import Image from "next/image";
import Link from "next/link";
import { useState, useEffect } from "react";
import {
  LightningBoltIcon,
  CalendarIcon,
  ClockIcon,
} from "@heroicons/react/outline";

const openStates = [
  { name: "Open", color: "bg-green-400", blobColor: "bg-green-500" },
  { name: "Gesloten", color: "bg-red-400", blobColor: "bg-red-500" },
];

const closedDays = 0;
const openHour = 10;
const closeHour = 18;

export const HeroHeaderBox = ({
  title,
  image,
  href,
  subText,
  applyOpenHours,
  incentives,
  className,
}) => {
  const [shopState, setShopState] = useState(openStates[0]);
  useEffect(() => {
    const dateTime = new Date();
    //setOpen
    if (
      dateTime.getHours() >= openHour &&
      dateTime.getHours() < closeHour &&
      dateTime.getDay() != closedDays
    ) {
      setShopState(openStates[0]);
    } else {
      setShopState(openStates[1]);
    }
  }, [shopState, setShopState]);

  const icons = [
    <LightningBoltIcon
      key="0"
      className="mr-2 h-6 w-6 flex-none"
      aria-hidden="true"
    />,
    <CalendarIcon
      key="1"
      className="mr-2 h-6 w-6 flex-none"
      aria-hidden="true"
    />,
    <ClockIcon key="2" className="mr-2 h-6 w-6 flex-none" aria-hidden="true" />,
  ];

  return (
    <div className="flex w-full flex-col space-y-2 md:space-y-4">
      <Link href={href} passHref>
        <motion.div
          layout
          whileHover={{ scale: 1.03 }}
          className={`overflow-hide h-44 cursor-pointer  justify-end rounded-xl bg-transparent p-4 transition-all duration-75 lg:h-52 lg:p-5 ${className}`}
        >
          <Image
            {...image.imageProps}
            placeholder="blur"
            priority={true}
            alt={title}
            layout="fill"
            objectFit="cover"
            objectPosition="center"
            className="rounded-xl"
          />

          <motion.div
            layout
            className="relative z-10 flex h-full w-full flex-col justify-between"
          >
            <motion.span
              layout
              className="flex w-max flex-row items-center justify-between space-x-2 rounded-lg bg-gray-900 bg-opacity-30 py-1 px-2 text-sm font-medium text-white backdrop-blur-xl"
            >
              <motion.span layout>{subText}</motion.span>
              <motion.div layout className="relative">
                {applyOpenHours ? (
                  <motion.span layout className="flex" title={shopState.name}>
                    <motion.span
                      layout
                      className={`absolute h-full  w-full animate-ping rounded-full ${shopState.color} opacity-70`}
                    ></motion.span>
                    <motion.span
                      layout
                      className={`relative  h-1.5 w-1.5 rounded-full ${shopState.blobColor}`}
                    ></motion.span>
                  </motion.span>
                ) : (
                  <motion.span
                    layout
                    className="flex h-1.5 w-1.5"
                    title="Online"
                  >
                    <motion.span
                      layout
                      className="absolute inline-flex h-full w-full animate-ping rounded-full bg-green-400 opacity-75"
                    ></motion.span>
                    <motion.span
                      layout
                      className="relative inline-flex h-1.5 w-1.5 rounded-full bg-green-500"
                    ></motion.span>
                  </motion.span>
                )}
              </motion.div>
            </motion.span>
            <motion.div
              layout
              className="flex max-w-max flex-row items-center space-x-3 rounded-lg bg-gray-900 bg-opacity-30 p-2 px-4 backdrop-blur-lg"
            >
              <motion.div layout className="">
                <motion.h4 layout className="font-semibold text-white">
                  {title}
                </motion.h4>
              </motion.div>
              <ArrowRightIcon className="h-6 w-6 flex-shrink-0 fill-current text-white" />
            </motion.div>
          </motion.div>
        </motion.div>
      </Link>
      <div className="flex flex-col items-center justify-center space-y-2 rounded-xl px-3 py-2 text-white">
        {incentives.map((incentive, index) => (
          <div
            key={incentive.name}
            className="text-md  flex items-center font-medium text-slate-600"
          >
            {icons[index]}
            <p>{incentive.name}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

HeroHeaderBox.propTypes = {
  title: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired,
  href: PropTypes.string.isRequired,
};
export default HeroHeaderBox;
