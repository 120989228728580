import { StarIcon } from "@heroicons/react/solid";

const AffiliateShowcaseItem = ({ title, text, score }) => {
  return (
    <div className="flex max-w-xs flex-shrink-0 flex-row items-center space-x-3 rounded-lg bg-white p-3 md:items-start">
      <div>
        <div className="flex flex-shrink-0 items-center justify-center rounded-full text-white font-semibold w-11 h-11 bg-ifixers" name="title" size="45">
          {getInitials(title)}
        </div>
      </div>
      <div className="space-y-1">
        <h6 className="md:text-md text-sm text-slate-700">{title}</h6>
        <span className="flex flex-row">
          {getStarArray(score).map((item) => item)}
        </span>
        <p className="md:text-md max-w-xs text-sm text-gray-500 md:block">
          {shortenText(text, 70)}
        </p>
      </div>
    </div>
  );
};

const shortenText = (text, chars) => {
  return text.length > chars ? text.slice(0, chars) + "..." : text;
};

const getInitials = (text) => {
  const ar = text.split(' ');
  if (ar[0] && ar[1]) {
    return (ar[0][0] + ar[1][0]).toUpperCase();
  } else {
    return "IN"
  }
}

const getStarArray = (score) => {
  let res = [];
  for (let i = 0; i < score; i++) {
    res.push(
      <StarIcon key={i} className="h-3 w-3 stroke-current text-yellow-500" />
    );
  }
  return res;
};
export default AffiliateShowcaseItem;
