import React from "react";
import PropTypes from "prop-types";
import { ErrorMessage } from "formik";

const CustomErrorMessage = ({ name, helperText }) => (
  <ErrorMessage
    name={name}
    helperText={helperText}
    render={(msg) =>
      typeof msg === "object" ? (
        <div className="mt-1 text-sm font-medium text-red-400">
          {msg[Object.keys(msg)[0]]}
        </div>
      ) : (
        <div className="mt-1 text-sm font-medium text-red-400">{msg}</div>
      )
    }
  />
);

CustomErrorMessage.propTypes = {
  name: PropTypes.string,
};

export default CustomErrorMessage;
