import { motion } from "framer-motion";
import { random } from "lodash-es";
import AffiliateShowcaseItem from "./AffiliateShowcaseItem";
const AffiliateShowcaseRow = ({ middle, items }) => {
  return (
    <div>
      <motion.div
        className="flex -translate-x-48 transform flex-row space-x-8"
        animate={{ translateX: middle ? -200 : 200 }}
        transition={{
          duration: random(14, 18),
          repeat: Infinity,
          repeatType: "reverse",
        }}
      >
        {items.map((item) => (
          <AffiliateShowcaseItem
            key={item.id}
            image={item.image}
            title={item.name}
            text={item.review}
            score={item.score}
          />
        ))}
      </motion.div>
    </div>
  );
};

export default AffiliateShowcaseRow;
