import SVG from "react-inlinesvg";

const BrandBox = ({ item }) => {
  return (
    <div className="flex h-24 w-24 flex-shrink-0 items-center justify-center rounded-2xl bg-white p-3 shadow-xl md:h-36 md:w-36">
      <div className="">
        <SVG src={item.logo_svg} className="max-h-[4rem] max-w-[6rem]" />
      </div>
    </div>
  );
};

export default BrandBox;
