//helper functions
export const getComponentByName = (content, componentName) => {
  let res = {};

  content.forEach((component) => {
    if (
      component.__component === componentName ||
      component.__typename === componentName
    ) {
      res = component;
    }
  });
  if (res == {}) {
    throw new Error("component not found");
  } else {
    return res;
  }
};

export const getComponentPosition = (content, componentName) => {
  let res = 0;
  content.forEach((component, index) => {
    if (component.__component === componentName) {
      res = index;
    }
  });
  return res;
};

//sorts
export const byPopularity = (a, b) => b.meta_interactions - a.meta_interactions;
