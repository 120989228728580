import { capitalize } from "lodash";

interface SeoInput {
  title: string;
  description: string;
  locale: string;
  localePageMap: object;
}

interface OpenGraph {
  type: string;
  url: string;
  title: string;
  description: string;
  locale: string;
  images: object[];
}

interface SeoOutput {
  title: string;
  description: string;
  languageAlternates: object[];
  openGraph: OpenGraph;
}

export const generateSeoObject = (params: SeoInput): SeoOutput => {
  const locales = Object.keys(params.localePageMap);
  const langAlts = [];
  locales.forEach((locale) => {
    if (locale !== params.locale) {
      langAlts.push({
        hrefLang: locale,
        href: `https://www.ifixers.be/${locale}/${params.localePageMap[locale]}`,
      });
    }
  });

  return {
    title: params.title,
    description: params.description,
    languageAlternates: langAlts,
    openGraph: {
      type: "website",
      url: `https://www.ifixers.be/${params.locale}/${
        params.localePageMap[params.locale]
      }`,
      title: params.title,
      description: params.description,
      locale: params.locale,
      images: [
        {
          url: "https://www.ifixers.be/_next/image?url=%2Fimages%2Fogimage.png&w=1200&q=75",
          width: 1200,
          height: 630,
          alt: "iFixers logo",
        },
      ],
    },
  };
};

export const isIphone = (type: string, brand: string): boolean =>
  type.toLowerCase() === "smartphone" && brand.toLowerCase() === "apple";

export const isIpad = (type: string, brand: string): boolean =>
  type.toLowerCase() === "tablet" && brand.toLowerCase() === "apple";

export const getTypeBrandSting = (type: string, brand: string): string => {
  if (isIphone(type, brand)) {
    return "iPhone";
  } else if (isIpad(type, brand)) {
    return "iPad";
  } else {
    return capitalize(brand) + " " + type;
  }
};

export const getAccurateGroupname = (
  type: string,
  brand: string,
  groupName: string
): string => {
  if (groupName.toLowerCase() === "apple-smartphone") {
    return getTypeBrandSting(type, brand);
  } else {
    return capitalize(brand) + " " + groupName;
  }
};
